import PropTypes from 'prop-types';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';
import Link from 'core/components/Link';

/**
 * копипаста со старго сайта.
 * Только выпилена камера и поправлены ошибки линтера
 */
function startAnimation(node) {
  if (!node) return;

  const isWebkit = 'WebkitAppearance' in document.documentElement.style;
  const canvas = document.querySelector('.canvas').getContext('2d');
  const smallCanvas = document.querySelector('.small').getContext('2d');
  const dots = [];
  let i = 0;
  const mouseCoord = { x: 10000, y: 10000 };
  // make small canvas with text
  smallCanvas.font = 'bold 50px Arial';
  smallCanvas.fillText('404', 5, 39);

  const enterFrameHandler = function() {
    var dot = {};
    var x = 0;
    var y = 0;
    var color = 0;
    var fillColor = '#ffffff';
    var distance = 0;
    var dotSize = 1;
    const canvasWidth = 1000;
    const canvasHeight = 400;
    // itar dots
    canvas.clearRect(0, 0, canvasWidth, canvasHeight);

    for (i = 0; i < 4000; i++) {
      x = i % 100;
      y = Math.floor(i / 100);
      color = smallCanvas.getImageData(x, y, 1, 1).data;
      dot = dots[i];

      if (color[3] < 100) {
        dot.currentX = 10000 * Math.random() - 6000;
        if (dot.currentX > -100) dot.currentX += 1200;
        dot.disabled = true;
      }
      canvas.fillStyle = 'rgb(255, 255, 255)';

      // darw dot
      canvas.fillStyle = fillColor;
      if (isWebkit) {
        canvas.fillRect(Math.round(dot.currentX), Math.round(dot.currentY), dotSize, dotSize);
      } else {
        if (!dot.disabled) {
          canvas.fillRect(dot.originalX, dot.originalY, dotSize, dotSize);
        }
      }
      // move dot
      if (dot.invert) {
        dot.currentX += (dot.farX - dot.currentX) / 10;
        dot.currentY += (dot.farY - dot.currentY) / 10;
      } else {
        dot.currentX += (dot.originalX - dot.currentX) / 10;
        dot.currentY += (dot.originalY - dot.currentY) / 10;
      }
      if (Math.abs(dot.originalX - dot.currentX) < 2 && !dot.invert) {
        dot.currentX = dot.originalX;
        dot.currentY = dot.originalY;
      } else if (Math.abs(dot.farX - dot.currentX) < 2 && dot.invert) {
        dot.currentX = dot.farX;
        dot.currentY = dot.farY;
        dot.invert = false;
      }
      // mouse distance
      distance = Math.sqrt((mouseCoord.x - dot.currentX) * (mouseCoord.x - dot.currentX) + (mouseCoord.y - dot.currentY) * (mouseCoord.y - dot.currentY));
      if (distance < 70) {
        dot.invert = true;
        dot.farX = canvasWidth * Math.random();
        dot.farY = canvasHeight * Math.random();
      }
    }
    if (isWebkit) {
      window.requestAnimationFrame(enterFrameHandler);
    }
  };

  for (i = 0; i < 4000; i++) {
    dots.push({
      originalX: (i % 100) * 10,
      originalY: Math.floor(i / 100) * 10,
      currentX: 1000 * Math.random(),
      currentY: 400 *  Math.random(),
      farX: 1000 * Math.random(),
      farY: 400 *  Math.random(),
      invert: false,
    });
  }

  enterFrameHandler();

  document.querySelector('.canvas').addEventListener('mousemove', function(event) {
    var rect = document.querySelector('.canvas').getBoundingClientRect();
    mouseCoord.y = event.clientY - rect.top;
    mouseCoord.x = event.clientX - rect.left;
  });

  document.querySelector('.canvas').addEventListener('mouseleave', function() {
    mouseCoord.y = -1000;
    mouseCoord.x = -1000;
  });
}

function NotFoundPage({ breakpoint, theme }) {
  return (
    <div className={'page404 ' + breakpoint}>
      <style jsx>{`
        .page404
          padding 40px 0
          position relative
          min-height 400px

        .content
          position absolute
          top 50%
          transform translateY(-50%)
          width 100%

        .text
          font-family ${theme.fonts.display}
          padding 0 40px
          font-size 36px
          line-height 1.1
          text-align center
          letter-spacing 1.3px
          font-weight lighter
          .mobile &
            font-size 28px

        .canvas
          max-width 1000px
          display block
          margin 0 auto

        .small
          position absolute
          top 10px
          left 10px
          display none

      `}</style>
      <Desktop>
        <canvas
          className='small'
          width='100px'
          height='40px'
        />
        <canvas
          className='canvas'
          width='1000px'
          height='400px'
          ref={startAnimation}
        />
      </Desktop>

      <div className='content'>
        <div className='text'>
          <Mobile>
            <p><b>404</b></p>
          </Mobile>
          Запрашиваемая страница не найдена. <br />
          Вернуться <Link to='/'>на главную</Link>
        </div>
      </div>
    </div>
  );
}

NotFoundPage.propTypes = {
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(NotFoundPage));
