import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { dateFormat } from 'core/utils/dates';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withCard from 'core/hocs/withCard';
import withThemeSwitcher from 'core/hocs/withThemeSwitcher';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric'];

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'ad_label',
];

const relationships = resolveRelationships(['image', 'rubric', 'rubric_root'], {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function CardPhotoMain(props) {
  const {
    content,
    theme,
    breakpoint,
    isDesktop,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: published,
    ad_label: adLabel,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  const padding = isDesktop ? SIDE_INDENT : SIDE_INDENT_MOBILE;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          padding ${padding}px
          padding-bottom 0

        .info
          padding 0 ${padding}px

        .date
        .headline
        .type
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}

          .link:hover &
            color ${theme.colors.active1}

        .date
        .type
          font-family ${theme.fonts.display}

        .headline
          font bold 18px/20px ${theme.fonts.text}

      `}</style>
    </scope>
  );

  return (
    <div className={cx(styles.cardPhotoMain, styles[breakpoint])}>
      <Image
        src={cover}
        previewSrc={previewCover}
        maxWidth={840}
        maxHeight={590}
      />
      <Link to={link} className={cx(styles.link, scoped.wrapClassNames('link'))}>
        <span className={cx(styles.type, scoped.wrapClassNames('type'))}>
          Фото дня
        </span>
        <div>
          <div className={cx(styles.date, scoped.wrapClassNames('date'))}>
            {dateFormat(published, 'dd/MM/yy')}
          </div>
          <div className={cx(styles.headline, scoped.wrapClassNames('headline'))}>
            {headline}
          </div>
        </div>
      </Link>
      <CardInfoLine
        className={cx(styles.info, scoped.wrapClassNames('info'))}
        adLabel={adLabel}
        rubric={rubric}
      />
      <scoped.styles />
    </div>
  );
}

CardPhotoMain.propTypes = {
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

const Card = withThemeSwitcher('dark')(withCard(CardPhotoMain));

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
