import PropTypes from 'prop-types';
import cx from 'classnames';

import { topicDateFormat } from 'core/utils/dates';
import { buildRubricUrl } from 'core/utils/url-helper';

import withTheme from 'core/hocs/withTheme';
import Link from 'core/components/Link';

import styles from './index.styl';

function CardInfoLine(props) {
  const {
    theme,
    adLabel,
    rubric,
    showTime,
    published,
    className,
    eventType,
  } = props;

  return (
    <div className={cx(className, styles.info, 'info')}>
      <style jsx>{`
      .info
        font 13px/15px ${theme.fonts.nuance}
        color ${theme.colors.hint}

        :global(a)
          color ${theme.colors.hint}
          &:hover
            color ${theme.colors.primary}

      `}</style>

      {eventType && <span className={styles.infoItem}>{eventType}</span>}
      {adLabel && <span className={styles.infoItem}>{adLabel}</span>}

      {rubric.enabled && (
        <Link
          to={buildRubricUrl(rubric.slug, rubric.root_slug)}
          type='secondary'
          className={styles.infoItem}
        >
          {rubric.root_title}
        </Link>
      )}

      {showTime && !rubric.enabled && published && (
        <time className={styles.infoItem}>
          {topicDateFormat(published)}
        </time>
      )}

    </div>
  );
}


CardInfoLine.defaultProps = {
  rubric: {},
  showTime: true,
};

CardInfoLine.propTypes = {
  eventType: PropTypes.string,
  theme: PropTypes.object,
  adLabel: PropTypes.string,
  rubric: PropTypes.object,
  showTime: PropTypes.bool,
  published: PropTypes.string,
  className: PropTypes.string,
};

export default withTheme(CardInfoLine);
