import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import Raker from 'core/components/Raker';

import ColorLine from 'site/components/ColorLine';

import CardEventMain, { CardEventMainType1 } from 'site/cards/CardEventMain';

function MainEvents({ content, isDesktop }) {
  return (
    <Raker
      content={content}
      title='События'
      headerComponent={ColorLine}
      sidebarGridSize={5}
      heroCard={CardEventMainType1}
      card={CardEventMain}
      interitemSpacing={isDesktop ? 40 : 20}
    />
  );
}

MainEvents.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(MainEvents);
