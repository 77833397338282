import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

function ColorLine(props) {
  const {
    children,
    theme,
    className,
  } = props;

  return (
    <div className={cx(styles.colorline, className)}>
      <style jsx>{`
        .${styles.colorline}
          font bold 14px/20px ${theme.fonts.nuance}
      `}</style>
      {children && (
        <span className={styles.title}>
          {children}
        </span>
      )}
    </div>
  );
}

ColorLine.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme(ColorLine);
