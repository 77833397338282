import compose from 'recompose/compose';

import withTheme from 'core/hocs/withTheme';

import './index.styl';

export default function(Component) {
  return compose(
    withTheme,
  )(props => {
    const {
      theme,
      ...otherProps
    } = props;

    return (
      <div className='native-banner'>
        <style jsx global>{`
          .native-banner
            .ad_native_title
            .ad_native_desc
              color ${theme.colors.primary}
            .ad_native_title
              font bold 18px/20px ${theme.fonts.display}
            a:hover .ad_native_title
              color ${theme.colors.active1}
            .ad_native_sponsored_clone
              font 13px/15px ${theme.fonts.nuance}
              color ${theme.colors.hint}
            .ad_native_img
              background-color ${theme.colors.active1}
              &:before
                padding-top ${100 / 1.42 + '%'}
            .ad_native_img__img
              background-color ${theme.colors.active1}
        `}</style>
        <Component {...otherProps} />
      </div>
    );
  });
}
