import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withTheme from 'core/hocs/withTheme';
import withTopic from 'core/hocs/withTopic';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import CommentsPreview from 'core/components/CommentsPreview';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import H6 from 'core/components/H6';
import { Desktop } from 'core/components/breakpoint';

import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';

import { Card2Type2 } from 'site/cards/Card2';

import {
  SuperFooter,
  NativeFooter,
} from 'site/components/Ads/desktop';
import Sponsored from 'site/components/Ads/Sponsored';
import Recommender from 'site/components/Recommender';
import ColorLine from 'site/components/ColorLine';
import AdWrapper from 'core/components/Ad/AdWrapper';

const relationships = resolveRelationships(...[
  ['read_more', 'tags', 'rubric', 'authors'],
  {},
  { read_more: { topic_ids: [] }, tags: [] },
]);

function TopicFooter(props) {
  const {
    theme,
    topic: {
      content,
    },
  } = props;

  const spacing = 30;

  const {
    id,
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const {
    read_more: {
      topic_ids: ids,
    },
    tags,
    authors,
  } = relationships(content);

  return (
    <Fragment>
      <style jsx>{`
        .tags
        .authors
          margin-top ${spacing}px
          font-size 16px
          color ${theme.colors.hint}
      `}</style>
      <MaxWidthWrapper>
        <Sponsored />

        {authors.length > 0 && (
          <div className='authors'>
            Автор{authors.length > 1 && 'ы'}:
            {authors.map(author => {
              return (
                <Link
                  key={author.id}
                  type='secondary'
                  to={`/authors/${author.attributes.slug}`}
                >
                  &emsp;{author.attributes.name}
                </Link>
              );
            })}
          </div>
        )}

        {tags.length > 0 && (
          <div className='tags'>
            <H6 is='span'>Теги</H6>
            {tags.map(tag => {
              return (
                <Link
                  key={tag.id}
                  type='secondary'
                  to={`/label/${tag.attributes.slug}`}
                >
                  &emsp;#{tag.attributes.title}
                </Link>
              );
            })}
          </div>
        )}
      </MaxWidthWrapper>

      <Desktop>
        <AdWrapper top={spacing}>
          <SuperFooter />
        </AdWrapper>
      </Desktop>

      {ids.length > 0 && (
        <Indent top={spacing}>
          <Feed
            title='Материалы по теме'
            headerComponent={ColorLine}
            card={Card2Type2}
            ids={ids.join(',')}
            interitemSpacing={15}
          />
        </Indent>
      )}

      {!isCommentsDisabled && (
        <Indent top={spacing}>
          <CommentsPreview xid={id} commentsUrl={`${link}/comments`} />
        </Indent>
      )}

      <Indent top={spacing}>
        <Recommender />
      </Indent>

      <Desktop>
        <AdWrapper top={spacing}>
          <NativeFooter />
        </AdWrapper>
      </Desktop>

    </Fragment>
  );
}

TopicFooter.propTypes = {
  topic: PropTypes.shape({
    infinityIndex: PropTypes.number,
    content: modelPropTypes(topicAttributes).isRequired,
  }),
  theme: PropTypes.object,
};

export default withTheme(withTopic(TopicFooter));
