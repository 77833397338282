import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import ThemeSwitch from 'core/components/ThemeSwitch';
import ContentBackground from 'core/components/ContentBackground';

import { SideIndent } from 'site/components/Wrappers';
import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

function LightPageWrapper(props) {
  const {
    children,
    isDesktop,
  } = props;

  const Wrapper = isDesktop ? SideIndent : Fragment;

  return (
    <Wrapper>
      <ThemeSwitch name='light'>
        <ContentBackground style={{ padding: isDesktop ? SIDE_INDENT : SIDE_INDENT_MOBILE }}>
          {children}
        </ContentBackground>
      </ThemeSwitch>
    </Wrapper>
  );
}

LightPageWrapper.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(LightPageWrapper);
