import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawContentQuery } from 'core/queries/searchPage';

import withBreakpoint from 'core/hocs/withBreakpoint';

import SearchPage from 'core/components/SearchPage';
import { Block, Section } from 'core/components/Grid';

import LightPageWrapper from 'site/components/LightPageWrapper';
import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import Card1 from 'site/cards/Card1';

import filters from './filters';


Card1.requiredPayloadImports = ['image'];

function Search({ location, history }) {
  const { data, isLoading } = useRequest(rawContentQuery({ location, history, Card1 }));
  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <SearchPage
              isLoading={isLoading}
              rawContent={data}
              filters={filters}
              ad1={SuperFooter}
              ad2={Context}
              ad1Mobile={Listing1}
              ad2Mobile={ListingSpec}
              card={Card1}
            />
          </Block>
          <InnerSideColumn showBoesque={false} />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

Search.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(Search);
