import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import TopicContent from 'core/components/TopicContent';

import {
  InRead,
  Native3,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

const widgetsContext = require.context('site/widgets', false, /\.js$/);

const blocks = widgetsContext
  .keys()
  .reduce((result, moduleName) => {
    const name = moduleName.replace(/^\.\/|\.js$/g, '');
    result[name] = widgetsContext(moduleName).default;
    return result;
  }, {});

const desktopAds = [
  {
    before: 200,
    after: 200,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 200,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 600,
    after: 200,
    float: true,
    renderer: Native3,
  },
  {
    before: 1200,
    after: 200,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 600,
  after: 200,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));

function SiteTopicContent({ isMobile }) {
  return (
    <TopicContent
      interitemSpacing={isMobile ? 15 : 30}
      desktopAds={desktopAds}
      mobileAds={mobileAds}
      blocks={blocks}
    />
  );
}

SiteTopicContent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteTopicContent);
