import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'ad_label',
];

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});

function Card4(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    ad_label: adLabel,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          transition color ${theme.animations.hover}
          color ${theme.colors.primary}
          font bold 24px/30px ${theme.fonts.display}
        .link
          &:hover
            .headline
              color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  return (
    <Link to={link} className={cx(scoped.wrapClassNames('link'), styles.card4)}>
      <div className={styles.image}>
        <Image
          src={cover}
          previewSrc={previewCover}
          maxWidth={377}
          aspectRatio={4 / 3}
        />
      </div>
      <div className={styles.content}>
        {adLabel && <CardInfoLine adLabel={adLabel} />}
        <div className={cx(styles.headline, scoped.wrapClassNames('headline'))}>
          {headline}
        </div>
      </div>
      <scoped.styles />
    </Link>
  );
}

Card4.propTypes = {
  type: PropTypes.oneOf([0, 1]),
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Card4.defaultProps = {
  type: 0,
};

const Card = withCard(Card4);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
