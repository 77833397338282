import withTheme from 'core/hocs/withTheme';

import './index.styl';

export default function NativeFooterStyles(Component) {
  return withTheme(props => (
    <div className='native-ad-footer'>
      <Component {...props} />
    </div>
  ));
}
