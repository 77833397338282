import PropTypes from 'prop-types';

import { dateFormat } from 'core/utils/dates';

import withTheme from 'core/hocs/withTheme';

import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';

function BeautyTime(props) {
  const {
    date: dateString,
    theme,
  } = props;

  const date = new Date(dateString);
  const now = new Date();

  let formattedDate;

  if (isSameDay(date, now)) {
    formattedDate = 'Сегодня';
  } else if (isSameDay(date, subDays(now, 1))) {
    formattedDate = 'Вчера';
  } else {
    formattedDate = dateFormat(dateString, 'dd MMMM');
  }
  return (
    <div>
      <style jsx>{`
        .time
          font 20px/1 ${theme.fonts.nuance}
          color ${theme.colors.active1}
          margin-bottom 3px
        .date
          font 13px/15px ${theme.fonts.nuance}
          color ${theme.colors.hint}
          margin-bottom 10px
      `}</style>
      <div className='time'>{dateFormat(dateString, 'HH:mm')}</div>
      <div className='date'>{formattedDate}</div>
    </div>
  );
}

BeautyTime.propTypes = {
  date: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(BeautyTime);
