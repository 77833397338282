import PropTypes from 'prop-types';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { withRouter } from 'core/libs/router';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Button from 'core/components/Button';
import Socializator from 'core/components/Socializator';
import CommentsCount from 'core/components/CommentsCount';
import CommentsIcon from 'site/icons/Comments';

import styles from './index.styl';

const settings = {
  'buttons': [
    'vkontakte',
    'odnoklassniki',
    'telegram',
    'whatsapp',
  ],
  'vkontakte': {
    'utm': 'utm_source=vksharing&utm_medium=social',
  },
  'odnoklassniki': {
    'utm': 'utm_source=oksharing&utm_medium=social',
  },
  'telegram': {
    'utm': 'utm_source=tgsharing&utm_medium=social',
  },
  'whatsapp': {
    'utm': 'utm_source=washaring&utm_medium=social',
  },
};

function onClick(history, url, event) {
  event.preventDefault();
  history.push(`${url}/comments`);
}

function SocialShare({ xid, breakpoint, history, url, isCommentsDisabled }) {
  return (
    <div to='comments' className={styles.social + ' ' + styles[breakpoint]}>
      <Socializator {...settings} />
      {xid && url && !isCommentsDisabled && (
        <div className={styles.comments} onClick={onClick.bind(this, history, url)}>
          <Button type='secondary' size='small'>
            <span className={styles.commentsTitle}>Обсудить</span>
            <CommentsIcon width={13} height={13} />
            <span className={styles.commentsCount}>
              <CommentsCount xid={xid} disableIcon />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

SocialShare.propTypes = {
  xid: PropTypes.string,
  breakpoint: breakpointPropTypes(),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isCommentsDisabled: PropTypes.bool,
  url: PropTypes.string,
};

export default withRouter(withBreakpoint(SocialShare));
