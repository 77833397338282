import PropTypes from 'prop-types';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';
import withBreakpoint from 'core/hocs/withBreakpoint';
import CookiesBanner from 'core/components/CookiesBanner';

import Shapka from '../Shapka';
import Scooter from '../Scooter';

import { Billboard } from '../Ads/desktop';
import { TopBanner } from '../Ads/mobile';

import { SideIndent } from 'site/components/Wrappers';

function SiteLayout({ children, isDesktop }) {
  return (
    <StickyContainer>
      <Layout>
        <Header>
          <Shapka />
        </Header>
        <Content>
          <SideIndent {...isDesktop && { top: 15 }}>
            <Desktop>
              <Billboard />
            </Desktop>
            <Mobile>
              <TopBanner />
            </Mobile>
          </SideIndent>
          {children}
        </Content>
        <Footer>
          <Scooter />
        </Footer>
        <CookiesBanner />
      </Layout>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(SiteLayout);
