import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Indent from './Indent';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

function SideIndent(props) {
  const {
    children,
    isMobile,
    ...otherProps
  } = props;

  const indent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <Indent
      left={indent}
      right={indent}
      {...otherProps}
    >
      {children}
    </Indent>
  );
}

SideIndent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SideIndent);
