import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import Quote from 'site/icons/Quote';
import CardInfoLine from 'site/components/CardInfoLine';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'ad_label',
  'published_at',
];

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});

function Card5(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    ad_label: adLabel,
    published_at: published,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .altHeadline
        .headline
          color ${theme.colors.primary}

        .altHeadline
          margin-top 10px
          font bold 18px/20px ${theme.fonts.text}

        .headline
          transition color ${theme.animations.hover}
          font bold 30px/30px ${theme.fonts.display}

        .link
          &:hover
            .headline
              color ${theme.colors.active1}

        .quote
          fill ${theme.colors.divider}
      `}</style>
    </scope>
  );

  return (
    <Link  to={link} className={cx(scoped.wrapClassNames('link'), styles.card5)}>
      {cover && (
        <div className={styles.image}>
          <Image
            src={cover}
            previewSrc={previewCover}
            maxWidth={400}
            aspectRatio={4 / 3}
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={cx(styles.headline, scoped.wrapClassNames('headline'))}>
          {headline}
        </div>
        {altHeadline && (
          <div className={cx(styles.altHeadline, scoped.wrapClassNames('altHeadline'))}>
            {altHeadline}
          </div>
        )}
        <CardInfoLine
          adLabel={adLabel}
          published={published}
        />
        <Quote
          className={cx(styles.quote, scoped.wrapClassNames('quote'))}
          width={105}
          height={90}
        />
      </div>
      <scoped.styles />
    </Link>
  );
}

Card5.propTypes = {
  type: PropTypes.oneOf([0, 1]),
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Card5.defaultProps = {
  type: 0,
};

const Card = withCard(Card5);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
