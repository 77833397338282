import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Mjolnir from 'core/components/Mjolnir';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import { Block, Section } from 'core/components/Grid';

import ColorLine from 'site/components/ColorLine';

import { Native1 } from 'site/components/Ads/desktop';

import { Card1Type1 } from 'site/cards/Card1';
/**
 * TODO: heroCard в главном на мобилке имеет анонс.
 * Нужно сделать новый тип карточки
 */
import Card2, { Card2Type3 } from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

import styles from './index.styl';


function Glavnoe({ content, isDesktop, breakpoint }) {
  return (
    <Section>
      <Block mobile={12} className={styles.main}>
        <Mjolnir
          content={content.main}
          title='Главное'
          headerComponent={ColorLine}
          heroCard={isDesktop ? Card3 : Card2Type3}
          card={isDesktop ? Card2 : Card2Type3}
          interitemSpacing={35}
          cardsReplacement={{
            1: withReplaceCardWithAd(Native1, isDesktop),
          }}
        />
      </Block>
      <Block desktop='35px' mobile={0} />
      <Block mobile={12} className={cx(styles.news, styles[breakpoint])}>
        <Feed
          content={content.news}
          title='Новости'
          headerComponent={ColorLine}
          interitemSpacing={20}
          card={Card1Type1}
        />
        <div className={styles.news__link}>
          <Link to='/news'>
            <Button size='small' type='secondary'>Еще новости</Button>
          </Link>
        </div>
      </Block>
    </Section>
  );
}

Glavnoe.propTypes = {
  content: PropTypes.shape({
    main: PropTypes.array,
    news: PropTypes.array,
  }),
  isDesktop: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Glavnoe);
